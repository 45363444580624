import styled from 'styled-components';

import { getThemeColor } from '@utils/styled';

export const StyledHr = styled.hr`
  display: block;
  width: 100%;
  height: 1px;
  margin: 0;
  border: none;
  background: ${({ theme, color }) => getThemeColor(color)({ theme })};
  ${({ theme, spacings }) => spacings && theme.getFluidSpacing('margin-top', ...spacings)}
  ${({ theme, spacings }) => spacings && theme.getFluidSpacing('margin-bottom', ...spacings)}
`;
